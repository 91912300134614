import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import searchIcon from './search.svg'
import MovieCard from './MovieCard';

const App=()=> {
  
  const API_URL = 'http://www.omdbapi.com/?i=tt3896198&apikey=b96bcc4a'

  const movie1 = {
    "Title": "Batman Returns",
    "Year": "1992",
    "imdbID": "tt0103776",
    "Type": "movie",
    "Poster": "https://m.media-amazon.com/images/M/MV5BOGZmYzVkMmItM2NiOS00MDI3LWI4ZWQtMTg0YWZkODRkMmViXkEyXkFqcGdeQXVyODY0NzcxNw@@._V1_SX300.jpg"
  }

  const [movies, setMovies] = useState([])
  const [search, setSearch] = useState('')

  const searchMovies = async (title) =>{
    const response = await fetch(`${API_URL}&s=${title}`)
    const data = await response.json()
    setMovies(data.Search)
    //document.title= {search}
    console.log(search)
    
  }

  useEffect(()=>{
    searchMovies('')
    document.title= 'Mi App'
  }, [])

  
  return (
    <>
      <div className='app'>
        <h1>MovieLand</h1>
        <div className='search'>
          <input placeholder='Search for movies' onChange={(e)=>setSearch(e.target.value)} value={search} />
          <img src={searchIcon} alt='search' onClick={()=>{searchMovies(search); document.title=search} } />
        </div>
      </div>



      {
        movies?.length > 0 
        ?
          <div className='container' >
            {movies.map((movie)=>(<MovieCard movie={movie}/>))}
          </div>
        :
          <div className='empty'><h2>No movies found</h2></div>

      }


      
    </>
  );
}

//http://www.omdbapi.com/?i=tt3896198&apikey=b96bcc4a

export default App;
